<template>
  <div ref="bigBox">
    <el-form style="width: 100%" label-width="100px" ref="form">
      <el-row>
        <el-col :span="8">
          <el-form-item label="报价对象" prop="enterpriseData.id">
            <div class="flex">
              <el-select label="询价人" v-model="valueSupplier" filterable placeholder="同行" @change="getsupplierRealsId">
                <el-option :label="item.realName +
                  '/' +
                  item.enterpriseName +
                  '/' +
                  item.bindPhone
                  " :value="item.id" :key="item.id" v-for="item in supplierReals"></el-option>
              </el-select>


              <div style="width: 20px"></div>
              <el-select label="询价人" v-model="valueCustomer" filterable placeholder="最终客户"
                @change="getinquiryCustomerId">
                <el-option :label="item.realName +
                  '/' +
                  item.enterpriseName +
                  '/' +
                  item.bindPhone
                  " :value="item.id" :key="item.id" v-for="item in customerReals"></el-option>
              </el-select>
            </div>
            <div style="color: #999999;padding-top: 17px;font-size: 10px;line-height: 16px;" v-if="enterpriseData.id">
              <div>公司名称: {{ enterpriseData.enterpriseName }}</div>
              <div>公司电话: {{ enterpriseData.bindPhone }}</div>
              <div>公司法人: {{ enterpriseData.realName }}</div>
              <div>公司地址: {{ enterpriseData.address }}</div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="主体公司" prop="sellerEnterpriseId">
            <el-radio-group v-model="sellerEnterpriseIndex">
              <el-radio v-for="(item, index) in enterpriseList" :key="item.enterpriseId" :label="index">{{
                item.enterpriseName }}</el-radio>
            </el-radio-group>


            <div style="color: #999999;margin: 0px;font-size: 10px;line-height: 16px;" v-if="enterpriseList.length">
              <div>公司名称: {{ enterpriseList[sellerEnterpriseIndex].enterpriseName }}</div>
              <div>公司类型: {{ enterpriseList[sellerEnterpriseIndex].type }}</div>
              <div>公司法人: {{ enterpriseList[sellerEnterpriseIndex].userName }}</div>
              <div>公司地址: {{ enterpriseList[sellerEnterpriseIndex].address }}</div>
              <div>公司传真: {{ enterpriseList[sellerEnterpriseIndex].fax }}</div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="9">
          <div class="p-top">
            <el-input v-model="value" :rows="8" type="textarea" placeholder="例如：
                  A9F29413  1
                  iC65N 2P C16A  2
                  LC1DT32M7C  1
                  6ES7511-1CK01-0AB0  6
                  6ES7521-1BH50-0AA0  3
                  6ES7540-1AB00-0AA0  3" style="margin-right: 40px;" />

          </div>
        </el-col>
        <el-col :span="10">
          <el-form-item label="备注">
            <el-input :rows="5" type="textarea" v-model="remarks" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="付款方式" prop="inquiryCustomerId">
            <el-select label="付款方式" v-model="payStatus" filterable placeholder="选择付款方式">
              <el-option v-for="item in paymentTypes" :key="item.type" :label="item.title"
                :value="item.title"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>


      <div class="flex">
        <div style="margin-left: 20px;display: flex;align-items: center;">
          <el-button size="mini" @click="search" type="primary">查询</el-button>
          <div style="color: #999999;margin-left: 20px;font-size: 12px;">
            批量添加订货号（型号）和数量，用空格隔开，也可从Excel中复制粘贴，然后按查询。</div>
        </div>
        <div style="display: flex;">
          <div class="flex">
            <div style="color: red;margin-left: 20px;font-size: 15px;font-weight: bold;">总数量：{{ allQty }}</div>
            <div style="color: red;margin-left: 20px;font-size: 15px;font-weight: bold;">总金额：{{ allSalesPrice }}
            </div>
            <div style="color: red;margin-left: 20px;font-size: 15px;font-weight: bold;">总利润：{{ allTotalProfitC }}
            </div>
          </div>

          <div class="flex" style="justify-content: center;margin-left: 20px;" ref="sBtn">
            <el-button size="mini" type="warning" @click="isSimpleOffer = !isSimpleOffer">{{ isSimpleOffer ? '复杂版' :
              '简单版' }}</el-button>
            <el-button size="mini" type="warning" @click="clickExportOffer">导出报价单</el-button>
            <el-button size="mini" type="primary" @click="confirm">确认</el-button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
  <div class="s-content">
    <div class="s-m">
      <offerBatchOpera :isSimpleOffer="isSimpleOffer" @valChange="valChange" />
      <lida-table :pagination="false" :data="data" :height="HEIGHT" border ref="table">
        <!--            <lida-table-column label="序号" width="50">-->
        <!--              <template #default="scope">-->
        <!--                {{scope.row.id}}-->
        <!--              </template>-->
        <!--            </lida-table-column>-->
        <el-table-column type="index" label="序号" width="50" align="center" />
        <lida-table-column label="产品信息">
          <template #default="scope">
            <div class="g-info">
              <div class="g-info-right" style="display: flex;flex-direction: column;align-items: center;">
                <div class="g-info-row g-info-title w-150px mb-2" v-if="scope.row.id">
                  {{ scope.row.productName }} | {{ scope.row.seriesName }}
                </div>
                <div class="g-info-row" v-if="scope.row.id">
                  <span>参数:</span>
                  <p>{{ scope.row.barCode }}</p>
                </div>
                <div class="g-info-row">
                  <span>订货号:</span>
                  <p>{{ scope.row.articleNo }}</p>
                </div>
                <div class="g-info-row">
                  <el-tag v-if="scope.row.id">{{ scope.row.brandName }}</el-tag>
                  <el-button type="warning" size="mini" @click="errorCorrection(scope.row.articleNo, scope.$index)"
                    v-if="!scope.row.id">纠错</el-button>
                </div>
              </div>
            </div>
          </template>
        </lida-table-column>
        <lida-table-column label="数量" prop="qty" :min-width="150" align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.qty" :min="0" size="small" :disabled="!isOffer"
              @change="inputLv(scope.row)" />
          </template>
        </lida-table-column>
        <el-table-column label="面价" prop="guidePrice" :min-width="150" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.guidePrice" @input="inputLv(scope.row)" placeholder="请输入面价"
              :disabled="!isOffer" />
          </template>
        </el-table-column>



        <lida-table-column label="采购折扣(%)" :min-width="150" align="center" v-if="!isSimpleOffer">
          <template #default="scope">
            <div class="t-row">
              <el-input-number v-model="scope.row.purchaseDis" size="small" :disabled="!isOffer"
                @change="inputLv(scope.row, 'purchaseDis')" />
            </div>
          </template>
        </lida-table-column>


        <lida-table-column label="采购单价" :min-width="$route.query.inquiryId ? 250 : 150" align="center"
          v-if="!isSimpleOffer">
          <template #default="scope">
            <div class="flex">
              <el-input-number v-model="scope.row.purchasePrice" size="small" :disabled="!isOffer"
                @change="inputLv(scope.row, 'purchasePrice')" />
              <div v-if="$route.query.inquiryId">
                <el-popover placement="right" :width="650" trigger="click" :disabled="timeDisabled">
                  <template #reference>
                    <el-button style="margin-left: 10px;" @click="timeDisabled = false"  size="mini" type="primary">报价历史{{
                      scope.row.enterpriseVOList.length }}条</el-button>
                  </template>
                  <el-table :data="scope.row.enterpriseVOList">
                    <el-table-column width="300" property="sellerEnterpriseName" label="报价公司" />
                    <el-table-column width="100" property="sellerName" label="报价人" />
                    <el-table-column width="50" property="qty" align="center" label="数量" />
                    <el-table-column width="100" property="salesPrice" align="center" label="报价价格" />
                    <el-table-column label="操作" width="100" align="center">
                      <template #default="scopeItem">
                        <el-button style="margin-left: 10px;" size="mini" @click="changePurchasePrice(scope.row,scopeItem.row)" type="primary">选择</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-popover>
              </div>

            </div>
          </template>
        </lida-table-column>

        <lida-table-column label="加点折扣" :min-width="150" align="center" class="redColor" v-if="!isSimpleOffer">
          <template #header="{ column }">
            <span style="color:red">{{ column.label }}</span>
          </template>
          <template #default="scope">
            <div class="t-row">
              <el-input-number v-model="scope.row.guideSite" size="small" :disabled="!isOffer"
                @change="inputLv(scope.row, 'guideSite',)" />
            </div>
          </template>
        </lida-table-column>

        <lida-table-column label="利润点数(%)" :min-width="150" align="center" v-if="!isSimpleOffer">
          <template #header="{ column }">
            <span style="color:red">{{ column.label }}</span>
          </template>
          <template #default="scope">
            <div class="t-row">
              <el-input-number v-model="scope.row.profitSite" size="small" :disabled="!isOffer"
                @change="inputLv(scope.row, 'profitSite',)" />
            </div>
          </template>
        </lida-table-column>

        <lida-table-column label="销售折扣(%)" :min-width="150" align="center">
          <template #default="scope">
            <div class="t-row">
              <el-input-number v-model="scope.row.salesDis" size="small" :disabled="!isOffer"
                @change="inputLv(scope.row, 'salesDis',)" />
            </div>
          </template>
        </lida-table-column>

        <lida-table-column label="销售单价" :min-width="150" align="center">
          <template #default="scope">
            <div class="t-row">
              <el-input-number v-model="scope.row.salesPrice" size="small" :disabled="!isOffer"
                @change="inputLv(scope.row, 'salesPrice',)" />
            </div>
          </template>
        </lida-table-column>

        <!-- <lida-table-column label="询价数量" prop="skuQty" :min-width="140" align="center" /> -->

        <lida-table-column label="生产日期" :min-width="120" align="center">
          <template #default="scope">
            <el-date-picker v-model="scope.row.manufactureDate" type="date" placeholder="请选择" :disabled="!isOffer"
              size="small" :disabled-date="disabledDate">
            </el-date-picker>
          </template>
        </lida-table-column>

        <lida-table-column label="渠道" :min-width="120" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.channel" placeholder="请选择" size="small" :disabled="!isOffer">
              <el-option v-for="item in channel" :key="item.title" :label="item.title" :value="item.title"></el-option>
            </el-select>
          </template>
        </lida-table-column>

        <lida-table-column label="地区" :min-width="120" align="center">
          <template #default="scope">
            <el-cascader v-model="scope.row.shipArea" :options="areaInfo" size="small"
              :props="{ expandTrigger: 'hover' }" style="width: 100%;" :disabled="!isOffer">
            </el-cascader>
          </template>
        </lida-table-column>

        <lida-table-column label="小计" fixed="right" :min-width="140" align="center">
          <template #default="scope">
            <div class="t-profit">
              <div class="t-profit-i">
                <span>小计:</span>
                <span>{{ ((scope.row.salesPrice * scope.row.qty) || 0).toFixed(2) }}</span>
              </div>
            </div>
          </template>
        </lida-table-column>

        <lida-table-column label="实际利润" fixed="right" :min-width="140" align="center">
          <template #default="scope">
            <div class="t-profit">
              <div class="t-profit-i">
                <span>实际利润:</span>
                <span>{{ scope.row.profitC }}</span>
              </div>
            </div>
          </template>
        </lida-table-column>

        <lida-table-column label="总利润" fixed="right" :min-width="140" align="center">
          <template #default="scope">
            <div class="t-profit">
              <div class="t-profit-i">
                <span>总利润:</span>
                <span>{{ scope.row.totalProfitC }}</span>
              </div>
            </div>
          </template>
        </lida-table-column>

        <lida-table-column label="操作" fixed="right">
          <template #default="scope">
            <el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
          </template>
        </lida-table-column>
      </lida-table>
    </div>
  </div>

  <el-dialog v-model="dialogTableVisible" width="60%">
    <div class="a">
      <div class="a-title">
        替换商品 (您输入的产品<label style="color: red">订货号/型号</label>可能有误，系统为您智能筛选出所输型号相近的产品供您选择。)
      </div>
      <el-row style="margin-bottom: 10px" :gutter="20">
        <el-col :span="6">
          <el-input v-model="wd" placeholder="请输入" @input="getProduct"></el-input>
        </el-col>
        <!--                    <el-col :span="6">-->
        <!--                        <el-button @click="getProduct">查询</el-button>-->
        <!--                    </el-col>-->
      </el-row>
      <lida-table :data="replaceData" border style="width: 100%" :cutHeight="0" :page="pageNo" :pageSize="pageSize"
        :total="total" :pagination="true" ref="table" @change="pageChange">
        <el-table-column property="brandName" align="center" label="品牌"></el-table-column>
        <el-table-column property="productName" align="center" label="商品名称"></el-table-column>
        <el-table-column property="barCode" align="center" label="型号"></el-table-column>
        <el-table-column property="articleNo" align="center" label="订货号"></el-table-column>
        <el-table-column property="guidePrice" align="center" label="面价"></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="scope">
            <el-button @click="replace(scope.row)">替换</el-button>
          </template>
        </el-table-column>
      </lida-table>
      <div class="a-footer">
        <el-button style="margin-right: 20px" size="mini" @click="dialogTableVisible = false">关闭
        </el-button>
      </div>
    </div>
  </el-dialog>
  <offer-info-dialog ref="offerInfo" v-model="offerInfoVisible" :data="offerData" :quoteInfos="quoteInfos"
    @success="exportSuccess" />
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { throttle, handleTreeData } from "@/common/js/common.js";
import offerBatchOpera from "@/components/publicComponent/offerBatchOpera.vue"; //批量操作
import offerProfitCompute from "@/common/js/offerProfitCompute.js";

import offerInfoDialog from "../components/dialog/offerInfo.vue"; // 导出报价单弹窗
import area from "@/common/json/area.json";
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  created() {
    this.data = this.$route.params.data ? JSON.parse(this.$route.params.data) : this.data;
    this.getUserList();
    this.getEnterpriseMyInfo()
  },
  data() {
    return {
      HEIGHT: 200,
      isOffer: true,
      ids: [],
      data: [],
      remarks: '',
      enterpriseData: {
        enterpriseId: '',
        id: '',
      },
      value: '',
      dialogTableVisible: false,
      sellerEnterpriseIndex: 0,
      enterpriseList: [],
      areaInfo: handleTreeData(area, "name", "name"), //地区信息
      payStatus: '',
      currentReplaceIndex: "",
      currentReplaceArticleNo: "",
      wd: "",
      replaceData: [],
      timer: null,
      quoteInfos: {},
      offerData: [],
      supplierReals: [], //询价人列表
      customerReals: [], //询价人列表
      offerInfoVisible: false,
      valueSupplier: '',
      valueCustomer: '',
      isSimpleOffer: true,
      timeDisabled:false
    };
  },
  computed: {
    ...mapState("mysaleMudule", ["channel", "paymentTypes"]),
    allQty() {
      const sumall = this.data.map(item => item.qty).reduce((prev, curr) => prev + curr, 0);
      return sumall
    },
    allSalesPrice() {
      const sumall = this.data.map(item => item.salesPrice - 0).reduce((prev, curr) => prev + curr, 0);
      return sumall.toFixed(2)
    },

    allTotalProfitC() {
      const sumall = this.data.map(item => item.totalProfitC - 0).reduce((prev, curr) => prev - 0 + curr - 0, 0);
      return sumall.toFixed(2)
    },
  },
  components: {
    offerBatchOpera,
    offerInfoDialog,
  },
  methods: {
    ...mapActions("mysaleMudule", ["quoteCreateByMyself",
      "userListByShareEnterpriseId", "enterpriseMyInfo", "skuPage"]),
    ...mapMutations(
      "mysaleMudule",
      ["setOfferSuccessType"],
    ),
    ...mapActions("purchaseMudule", [
      "inquiryInfo", //获取询价详情
    ]),

    valChange(val, key, isCompute) {
      this.data.map(item => {
        item[key] = val;
        if (isCompute) {
          item = this.inputLv(item, key);
        }
        return item;
      })
    },
    changePurchasePrice(row,row_c){
      row.purchasePrice = row_c.salesPrice
      row = offerProfitCompute(row, 'purchasePrice');
      this.timeDisabled = true
    },
    //打开报价单弹框
    clickExportOffer() {
      this.quoteInfos = {
        "sellerName": this.enterpriseList[this.sellerEnterpriseIndex].userName,
        "sellerEnterpriseName": this.enterpriseList[this.sellerEnterpriseIndex].enterpriseName,
        "sellerPhone": this.enterpriseList[this.sellerEnterpriseIndex].userMobilePhone,
        "sellerEnterpriseAddress": this.enterpriseList[this.sellerEnterpriseIndex].address,
        "payStatus": this.payStatus,
        "buyerName": this.enterpriseData.realName,
        "buyerPhone": this.enterpriseData.bindPhone,
        "buyerEnterpriseName": this.enterpriseData.enterpriseName,
        "buyerEnterpriseAddress": this.enterpriseData.address,
      }
      this.offerData = this.data.filter(data => { return data.id !== "" && data.id && data.id != null })
      if (this.quoteInfos.buyerEnterpriseName && this.offerData.length) {
        this.$refs.offerInfo.offerTotalAmount()
        this.offerInfoVisible = true;
      } else {
        !this.quoteInfos.buyerEnterpriseName && ElMessage({
          type: "warning",
          message: "请选择报价对象",
        });
        !this.offerData.length && ElMessage({
          type: "warning",
          message: "请选择报价产品",
        });
      }
    },

    //报价对象变化
    getinquiryCustomerId(val) {
      if (this.customerReals.filter(data => { return data.id == val }).length) {
        this.valueCustomer = val;
        this.valueSupplier = "";
        this.enterpriseData = this.customerReals.filter(data => { return data.id == val })[0]
      }

    },
    //报价对象变化
    getsupplierRealsId(val) {
      if (this.supplierReals.filter(data => { return data.id == val }).length) {
        this.valueSupplier = val;
        this.valueCustomer = "";
        this.enterpriseData = this.supplierReals.filter(data => { return data.id == val })[0]
      }

    },
    search() { //搜索的点击事件
      if (this.value) {
        this.skuPage({
          wdBatch: this.value,
          pageNo: 1,
          pageSize: 30,
        }).then(res => {
          let { code, data } = res.data;
          if (code === "0") { //请求成功
            data.rows.map(item => {
              item.qty = (item.wdNumber - 0) || 1; //数量
              item.manufactureDate = item.manufactureDate || moment().format(
                "YYYY-MM-DD"); //生产日期
              item.channel = item.channel || '现货'; //渠道
              item.purchasePrice = item.purchasePrice || ''; //采购单价
              item.profitSite = item.profitSite || ''; //利润点数
              item.guideSite = item.guideSite || ''; //面价点数
              item.enterpriseVOList = [];
              item.guidePrice = (item.guidePrice || item.skuGuidePrice || 0).toFixed(2);
              item.shipArea = typeof item.shipArea === 'string' ? item.shipArea.split(',') :
                item.shipArea || [];
              item.numType = 'qty';
              return offerProfitCompute(item);
            })

            this.data = this.data.concat(data.rows)
            this.HEIGHT = 47 + this.data.length * 115
          }
        })
      } else {
        ElMessage({
          type: 'warning',
          message: '请输入查询条件',
        })
      }
      //阻止浏览器默认事件
      let e = window.event || arguments[0];
      if (e.key == 'Enter' || e.code == 'Enter' || e.keyCode == 13) {
        e.returnValue = false;
        return false;
      }
    },
    //打开纠错
    async errorCorrection(articleNo, index) {
      this.dialogTableVisible = true;
      this.currentReplaceArticleNo = articleNo;
      this.currentReplaceIndex = index;
      this.wd = articleNo;
      await this.getProduct();
    },
    //纠错获取商品列表
    getProduct() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.replaceData = [];
      if (!this.wd) {
        return;
      }
      this.timer = setTimeout(async () => {
        let result = await this.skuPage({
          wd: this.currentReplaceArticleNo,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          wd: this.wd,
        });
        this.replaceData = result.data.data.rows;
        this.total = result.data.data.totalCount;
        this.pageSize = result.data.data.pageSize;
        this.pageNo = result.data.data.pageNo;
      }, 200);
    },
    //确认纠错
    replace(row) {
      row.qty = 1;
      row.manufactureDate = moment().format("YYYY-MM-DD")
      row.channel = '现货'
      row.enterpriseVOList = [];
      row.totalProfitC = 0;
      row.salesPrice = 0;
      row.purchasePrice = row.purchasePrice || ''; //采购单价
      row.profitSite = row.profitSite || ''; //利润点数
      row.guideSite = row.guideSite || ''; //面价点数
      row.guidePrice = (row.guidePrice || row.skuGuidePrice || 0).toFixed(2);
      row.row = typeof row.row === 'string' ? row.row.split(',') : row.row || [];
      row.numType = 'qty';
      this.data[this.currentReplaceIndex] = { ...offerProfitCompute(row) };
      this.dialogTableVisible = false;
    },
    //获取询价客户列表
    getUserList() {
      this.userListByShareEnterpriseId({}).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {

          this.supplierReals = data.filter((data) => {
            return data.userType === "0";
          });
          this.customerReals = data.filter((data) => {
            return data.userType === "1";
          });
          if (this.$route.query.inquiryId) {
            this.isSimpleOffer = false
            this.getInquiryData()
          }
        }
      });
    },
    //获取分销公司信息
    getEnterpriseMyInfo() {
      this.enterpriseMyInfo({}).then((res) => {
        let { data, code } = res.data;
        this.enterpriseList = data
      });
    },
    deleteRow(obj) {
      //删除商品的事件
      this.data.splice(obj.$index, 1);
      this.HEIGHT = 47 + this.data.length * 115
    },
    addSuccess(data) {
      //添加成功的回调
      var arr = JSON.parse(JSON.stringify(this.data));
      data.map((item) => {
        if (!arr.some((v) => v.id === item.id)) {
          arr.push(item);
        }
      });
      this.data = [...arr];
    },
    inputLv(row, type, linkage) {
      row = offerProfitCompute(row, type, linkage);
    },
    confirm() {
      if (!this.enterpriseData.id) {
        return ElMessage({
          type: 'warning',
          message: '请选择报价对象',
        })
      }
      var data = JSON.parse(JSON.stringify(this.data));
      var a = data.filter(v =>
        (v.salesPrice || this.isSimpleOffer) &&
        (v.profitSite || this.isSimpleOffer) &&
        v.shipArea
      );
      if (a.length) {
        for (var i = 0; i < a.length; i++) {
          a[i].shipArea = a[i].shipArea.join(',');
          a[i].manufactureDate = moment(a[i].manufactureDate).format('YYYY-MM-DD');
          a[i].skuId = a[i].skuId || a[i].id;
        }
        if (a.length != data.length) {
          ElMessageBox.confirm(
            '没填完报价信息的商品会自动过滤，是否继续报价?',
            '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
          }
          ).then(() => {
            this.offerGoods(a)
          }).catch(() => { })
        } else {
          this.offerGoods(a)
        }
      } else {
        for (var i = 0; i < data.length; i++) {
          if (!data[i].manufactureDate) {
            return ElMessage({
              type: 'warning',
              message: '请选择生产日期',
            })
          } else if (!data[i].channel) {
            return ElMessage({
              type: 'warning',
              message: '请选择渠道',
            })
          } else if (!data[i].shipArea || !data[i].shipArea.length) {
            return ElMessage({
              type: 'warning',
              message: '请选择地区',
            })
          } else if (!data[i].salesPrice && !this.isSimpleOffer) {
            return ElMessage({
              type: 'warning',
              message: '请填写销售单价',
            })
          } else if (!data[i].profitSite && !this.isSimpleOffer) {
            return ElMessage({
              type: 'warning',
              message: '请填写利润点数',
            })
          } else if (!data[i].guideSite && !this.isSimpleOffer) {
            return ElMessage({
              type: 'warning',
              message: '请填写面价点数',
            })
          }
        }
      }
    },
    offerGoods(a) {
      this.quoteCreateByMyself({
        itemRequestVOList: a,
        quoteRequestVO: {
          buyerEnterpriseId: this.enterpriseData.enterpriseId,
        },
        QuoteSaveVO: {
          buyerId: this.enterpriseData.id,
          buyerEnterpriseId: this.enterpriseData.enterpriseId,
          sellerEnterpriseId: this.enterpriseList[this.sellerEnterpriseIndex].enterpriseId
        },
        sellerEnterpriseId: this.enterpriseList[this.sellerEnterpriseIndex].enterpriseId,
        buyerId: this.enterpriseData.id,
        buyerEnterpriseId: this.enterpriseData.enterpriseId,
        quoteType:this.sellerEnterpriseIndex+1,
      }).then((res) => {
        let { data, code } = res.data;
        if (code === "0") {
          ElMessage({
            type: "success",
            message: "报价成功",
          });
          //跳转到我的报价页面
          //this.setOfferSuccessType(true);
          this.$router.push({
            path: "/mysale/customerOffer",
          });
        }
      });
    },
    getInquiryData() {
      this.inquiryInfo({
        inquiryId: this.$route.query.inquiryId,
      }).then((res) => {
        console.log(res);
        let { data, code } = res.data;

        data.itemRequestVOList.map(item => {
          item.qty = (item.skuQty - 0) || 1; //数量
          item.manufactureDate = item.manufactureDate || moment().format(
            "YYYY-MM-DD"); //生产日期
          item.channel = item.channel || '现货'; //渠道
          item.enterpriseVOList = [];
          item.purchasePrice = item.purchasePrice || ''; //采购单价
          item.profitSite = item.profitSite || ''; //利润点数
          item.guideSite = item.guideSite || ''; //面价点数
          item.guidePrice = (item.guidePrice || item.skuGuidePrice || 0).toFixed(2);
          item.shipArea = typeof item.shipArea === 'string' ? item.shipArea.split(',') : item.shipArea || [];
          item.numType = 'qty';
          if (data.forSku && data.forSku.length) {
            item.enterpriseVOList = data.forSku.map(item2 => {
              return item2.skuId == item.skuId ? item2.enterpriseVOList : []
            })[0]
          }

          return offerProfitCompute(item);
        })
        console.log(data.itemRequestVOList);
        this.getsupplierRealsId(data.inquiryRequestVO.inquiryCustomerId)
        this.getinquiryCustomerId(data.inquiryRequestVO.inquiryCustomerId)
        this.data = this.data.concat(data.itemRequestVOList)
        this.HEIGHT = 47 + this.data.length * 115
      });
    },
  },



};
</script>

<style lang="less">
@import "@/module/mysaleMudule/style/editOfferInfo.less";

.ao-t {
  padding-bottom: 10px;
  font-size: 16px;

  .ao-t1 {
    color: #d84146;
    font-weight: bold;
    padding-bottom: 5px;
  }
}

.ao-opear {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-top {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-left: 20px;
}
</style>
